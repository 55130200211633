











































































import InputGroup from "@/components/InputGroup.vue";
import { UpstreamIdpId } from "@/graphql/types";
import injectStrict from "@/utilities/injectstrict";
import { maxLength, required, email } from "@/utilities/validators";
import { defineComponent, toRefs, ref, watch } from "@vue/composition-api";
import useVuelidate from "@vuelidate/core";
import { UserKey } from "../symbols";
import useProfile from "@/composables/useProfile";

export default defineComponent({
  components: { InputGroup },
  setup() {
    const { isCentralAdmin } = useProfile();
    const user = injectStrict(UserKey);
    const v$ = useVuelidate(useValidations(), { ...toRefs(user) });
    const localIdps$ = ref([] as UpstreamIdpId[]);
    watch(
      () => user,
      (newValue) => {
        localIdps$.value = (
          [...(newValue.upstreamIdpIds ?? [])] as UpstreamIdpId[]
        ).filter((idp) => idp && idp.upstreamIdpUserId !== user.nemloginId);
      },
      { immediate: true, deep: true }
    );
    return {
      v$,
      user,
      localIdps$,
      isCentralAdmin,
    };
  },
});

function useValidations() {
  return {
    firstname: { required, maxLength: maxLength(255) },
    lastname: { required, maxLength: maxLength(255) },
    description: { maxLength: maxLength(1024) },
    email: { required, email },
    nemloginId: {},
  };
}
