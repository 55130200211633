import { User, useDeleteUserMutation } from "@/graphql/types";
import useDeleteFromCache from "@/composables/useDeleteFromCache";
import { ParentQueryKey } from "../symbols";
import injectStrict from "@/utilities/injectstrict";

export default function (user: User) {
  const parentQuery = injectStrict(ParentQueryKey);
  const mutation = useDeleteUserMutation({});

  function call() {
    mutation.mutate(
      {
        id: user.id,
      },
      {
        update: (cache) => {
          const cachedData = cache.readQuery(parentQuery);

          cache.writeQuery({
            ...parentQuery,
            data: {
              ...cachedData,
              users: {
                ...cachedData?.users,
                totalCount: cachedData?.users?.totalCount
                  ? cachedData.users?.totalCount - 1
                  : undefined,
                nodes: cachedData?.users?.nodes?.filter(
                  (element) => element?.id != user.id
                ),
              },
            },
          });

          // We need to clear organization cache, as it contains information about amount of users per org. Roles may have one role which the user was assigned a role
          if (user.organization != null) {
            useDeleteFromCache(cache, "organizations");
          }

          useDeleteFromCache(cache, "roles");
        },
      }
    );
  }

  return {
    ...mutation,
    call,
  };
}
