

































import Table from "@/components/Table.vue";
import useNavigateToApplication from "@/composables/navigation/useNavigateToApplication";
import useCanNavigate from "@/composables/useCanNavigate";
import useCanRemove from "@/composables/useCanRemove";
import useCanAssign from "@/composables/useCanAssign";
import {
  Application,
  useGetUserApplicationsQuery,
  useRemoveApplicationAdminAssignmentMutation,
} from "@/graphql/types";
import IMenu from "@/interfaces/IMenu";
import { TableField } from "@/types/TableField";
import injectStrict from "@/utilities/injectstrict";
import { useResult } from "@vue/apollo-composable";
import { computed, defineComponent, toRef, watch } from "@vue/composition-api";
import { UserKey } from "../symbols";
import AssignApplicationPopup from "./AssignApplicationPopup.vue";
import useRemoveOption from "@/composables/options/useRemoveOption";
import useShowOption from "@/composables/options/useShowOption";
import { FullScreenKey } from "@/symbols";

export default defineComponent({
  components: { Table, AssignApplicationPopup },
  setup(props, { root }) {
    const user = injectStrict(UserKey);
    const applicationAssignments = toRef(user, "applicationAssignments");
    const fullscreen = injectStrict(FullScreenKey);

    const { result, loading, error, refetch } = useGetUserApplicationsQuery(
      () => ({ id: user.id }),
      () => ({
        fetchPolicy: "no-cache",
        enabled: user.id != null || user.id != "",
      })
    );

    const removeMutation = useRemoveApplicationAdminAssignmentMutation({});

    watch(
      () => useResult(result).value,
      (newValue) => {
        applicationAssignments.value = newValue?.applicationAssignments ?? [];
      },
      { immediate: true }
    );

    function assignedApplication(application: Application) {
      applicationAssignments.value?.push(application);
    }

    function removedApplication(application: Application) {
      applicationAssignments.value =
        applicationAssignments.value?.filter(
          (element) => element?.id != application.id
        ) ?? [];
    }

    return {
      user,
      loading,
      assignedApplication,
      removedApplication,
      useCanAssign,
      items: computed(() => applicationAssignments.value ?? []),
      options: computed<IMenu<Application>[]>(() => [
        {
          ...useRemoveOption(root.$tc("users.application_assignment")),
          disabled: (item) =>
            !useCanRemove("UserApplicationAssignment", {
              user: user,
              application: item,
            }),
          command: async (item) => {
            if (item == null) return;
            var result = await removeMutation.mutate({
              input: {
                applicationId: item?.id,
                userId: user.id,
              },
            });
            if (result?.errors == null) removedApplication(item);
          },
        },
        {
          ...useShowOption(root.$tc("application.title")),
          disabled: (item) => !useCanNavigate("Application", item),
          command: (item) => useNavigateToApplication(root.$router, item?.id),
        },
      ]),
      fields: computed<TableField[]>(() => [
        {
          label: root.$tc("applications.fields.name"),
          key: "name",
          sortable: true,
          filter: true,
        },
        {
          label: root.$tc("applications.fields.owner"),
          key: "ownerOrganization.name",
          sortable: true,
          filter: true,
        },
        ...(fullscreen.value
          ? [
              {
                label: root.$tc("applications.fields.role_count"),
                key: "roles.totalCount",
                sortable: true,
                filter: false,
                width: "10%",
              },
            ]
          : []),
      ]),
    };
  },
});
