import {
  BS_APP_ADMIN,
  BS_CENTRAL_ADMIN,
  BS_LOCAL_ADMIN,
  BS_SUPPORT_ADMIN,
} from "@/constants";
import { User } from "@/graphql/types";
import { computed } from "@vue/composition-api";

export default function (user: User) {
  return {
    isApplicationAdmin: computed(() =>
      user.roleAssignments?.some(
        (element) => element?.role?.attribute == BS_APP_ADMIN
      )
    ),
    isCentralAdmin: computed(() =>
      user.roleAssignments?.some(
        (element) => element?.role?.attribute == BS_CENTRAL_ADMIN
      )
    ),
    isLocalAdmin: computed(() =>
      user.roleAssignments?.some(
        (element) => element?.role?.attribute == BS_LOCAL_ADMIN
      )
    ),
    isSupportAdmin: computed(() =>
      user.roleAssignments?.some(
        (element) => element?.role?.attribute == BS_SUPPORT_ADMIN
      )
    ),
  };
}
