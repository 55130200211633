


























import Sidebar, { useConfirmDelete } from "@/components/Sidebar.vue";
import {
  computed,
  defineComponent,
  provide,
  reactive,
  ref,
  toRef,
  watch,
} from "@vue/composition-api";
import Tapbar from "@/components/Tapbar.vue";
import CreateWizard from "@/components/CreateWizard.vue";
import ParentView from "@/components/ParentView.vue";
import { useGetUserQuery, User } from "@/graphql/types";
import UserRoles from "./Components/UserRoles.vue";
import UserData from "./Components/UserData.vue";
import UserApplications from "./Components/UserApplications.vue";
import ITabContent from "@/interfaces/ITabContent";
import useVuelidate from "@vuelidate/core";
import useUpdateUserMutation from "./Composables/useUpdateUserMutation";
import useDeleteUserMutation from "./Composables/useDeleteUserMutation";
import { useResult } from "@vue/apollo-composable";
import { IsUserEditKey, UserKey } from "./symbols";
import useProfile from "@/composables/useProfile";
import IMenu from "@/interfaces/IMenu";
import { BS_CENTRAL_ADMIN } from "@/constants";
import useUserIs from "@/composables/useUserIs";
import { EditableKey, IdKey } from "@/symbols";
import useCancelButton from "@/composables/buttons/useCancelButton";
import useSaveButton from "@/composables/buttons/useSaveButton";
import ChangelogTableVue from "@/components/ChangelogTable.vue";
import useGoToParent from "@/composables/useGoToParent";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: { Sidebar, Tapbar, CreateWizard, ParentView },
  setup(props, { emit, root }) {
    const currentTab = ref(0);
    const {
      isCentralAdmin,
      isLocalAdmin,
      isAppAdmin,
      isHomeOrg,
      isSupportAdmin,
    } = useProfile();

    // Refs
    const hasChanges = ref<boolean | null>(null);
    const v$ = useVuelidate();
    const user = reactive<User>({
      id: "",
      firstname: "",
      lastname: "",
      description: "",
      organization: {},
      roleAssignments: [],
      applicationAssignments: [],
      email: "",
    });

    const {
      isApplicationAdmin: userIsApplicationAdmin,
      isCentralAdmin: userIsCentralAdmin,
      isLocalAdmin: userIsLocalAdmin,
      isSupportAdmin: userIsSupportAdmin,
    } = useUserIs(user);

    const isOwnOrganization = computed(() => isHomeOrg(user.organization));

    const editable = computed(
      () =>
        isCentralAdmin.value ||
        (isLocalAdmin.value && isHomeOrg(user.organization))
    );

    provide(UserKey, user);
    provide(IdKey, toRef(user, "id"));
    provide(IsUserEditKey, true);
    provide(EditableKey, editable);

    const { result, loading } = useGetUserQuery(() => ({ id: props.id }), {
      fetchPolicy: "no-cache",
    });

    const allowDeleteUser = computed(() => {
      if (
        user.roleAssignments?.some(
          (element) => element?.role?.attribute == BS_CENTRAL_ADMIN
        )
      ) {
        return false;
      }

      if (isCentralAdmin.value) return true;
      if (isLocalAdmin.value && isOwnOrganization.value) return true;
      return false;
    });

    const deleteMutation = useDeleteUserMutation(user);
    const updateMutation = useUpdateUserMutation(user);

    watch(
      () => useResult(result).value,
      (newValue) => {
        if (newValue) Object.assign(user, newValue);
      },
      { immediate: true }
    );

    watch(
      () => [user.firstname, user.lastname, user.description, user.email],
      () => {
        if (loading.value) return;
        if (hasChanges.value == null) {
          hasChanges.value = false;
        } else {
          hasChanges.value = true;
        }
      },
      { deep: true }
    );

    updateMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    deleteMutation.onDone(() => {
      useGoToParent(root.$router);
    });

    return {
      currentTab,
      user,
      loading: computed(
        () =>
          loading.value ||
          updateMutation.loading.value ||
          deleteMutation.loading.value
      ),
      menuItems: computed<IMenu[]>(() => [
        {
          name: root.$tc("users.delete"),
          icon: "trash-alt",
          disabled: () => !editable.value || !allowDeleteUser.value,
          command: async () => {
            if (await useConfirmDelete(user.fullname ?? "", null))
              deleteMutation.call();
          },
        },
      ]),
      tabs: computed<ITabContent[]>(() =>
        isOwnOrganization.value || isCentralAdmin.value || loading.value
          ? [
              {
                title: root.$tc("common.steps.data"),
                component: UserData,
                primaryButton: {
                  ...useSaveButton(async () => {
                    if (await v$.value.$validate()) updateMutation.call();
                  }),
                  disabled:
                    !editable.value ||
                    (!hasChanges.value ?? false) ||
                    v$.value.$error,
                },
                secondaryButton: useCancelButton(() =>
                  useGoToParent(root.$router)
                ),
              },
              {
                title: root.$tc("roles.title"),
                component: UserRoles,
                supportsFullscreen: true,
              },
              {
                title: root.$tc("users.application_admin"),
                component: UserApplications,
                supportsFullscreen: true,
                visible:
                  (isCentralAdmin.value || isLocalAdmin.value) && // Data-restriction: only CentralAdmin or LocalAdmin can assign application
                  userIsApplicationAdmin.value &&
                  !userIsLocalAdmin.value &&
                  !userIsCentralAdmin.value &&
                  !userIsSupportAdmin.value,
              },
              {
                title: root.$tc("history.title"),
                component: ChangelogTableVue,
                visible: isCentralAdmin.value || isSupportAdmin.value,
              },
            ]
          : [
              {
                title: root.$tc("roles.title"),
                component: UserRoles,
                supportsFullscreen: true,
                editable: editable.value,
              },
            ]
      ),
    };
  },
});
