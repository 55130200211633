

























































import Popover, { PopoverProps } from "@/components/Popover.vue";
import Table from "@/components/Table.vue";
import useProfile from "@/composables/useProfile";
import {
  Application,
  useAssignApplicationAdminAssignmentMutation,
  useGetSelectableApplicationsLazyQuery,
  useRemoveApplicationAdminAssignmentMutation,
} from "@/graphql/types";
import { TableField } from "@/types/TableField";
import { useResult } from "@vue/apollo-composable";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";

export default defineComponent({
  components: { Popover, Table },
  emits: ["assigned", "removed"],
  props: {
    ...PopoverProps,
    userId: {
      type: String,
      required: true,
    },
    assignedApplications: {
      type: Array as PropType<Application[]>,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    // Data
    const { profile, isLocalAdmin } = useProfile();

    const show = ref(false);
    const { result, loading, load } = useGetSelectableApplicationsLazyQuery();

    const assignMutation = useAssignApplicationAdminAssignmentMutation({});
    const removeMutation = useRemoveApplicationAdminAssignmentMutation({});

    watch(
      () => show.value,
      (newValue) => {
        if (newValue) load();
      }
    );

    // Functions
    async function addApplication(application: Application) {
      const result = await assignMutation.mutate({
        input: {
          applicationId: application.id,
          userId: props.userId,
        },
      });
      if (result?.errors == null) emit("assigned", application);
    }

    async function removeApplication(application: Application) {
      const result = await removeMutation.mutate({
        input: {
          applicationId: application.id,
          userId: props.userId,
        },
      });
      if (result?.errors == null) emit("removed", application);
    }

    function isAssigned(application: Application) {
      return props.assignedApplications.some(
        (element) => element.id == application.id
      );
    }

    return {
      show,
      loading,
      // Regarding Bug 93145: Can't assign local appliation administrators to any applications, logged in as the local administrator for the organization
      // => LA/AA can assigne all selectable applications. So we no need to filter applicaiton of homeOrg anymore
      applications: useResult(result, [], (data) => data.applications.nodes),
      mutationLoading: computed(
        () => assignMutation.loading.value || removeMutation.loading.value
      ),
      addApplication,
      removeApplication,
      fields: computed<TableField[]>(() => [
        {
          key: "name",
          label: root.$tc("applications.fields.name"),
          filter: true,
        },
      ]),
      isAssigned,
    };
  },
});
